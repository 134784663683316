<template>
    <!--
    Страница уведомлений
    -->

    <el-container class="profile">

        <el-card class="box-card notification" v-for="elem in paginatedNotifications">
            <div class="notification-item">
                <div class="left">
                    <div class="date">
                        <i class="fas fa-exclamation-circle"></i>
                        <span class="date-text">{{ elem.ppnDttm | formatDate }}</span>
                    </div>
                    <span class="text"><vue-markdown :key="elem.id">{{ elem.msg }}</vue-markdown></span>
                </div>
                <i class="fas fa-eye eye" @click="openNotification(elem)"></i>
            </div>
        </el-card>

        <el-pagination
            class="notifications-pagination"
            background
            :current-page.sync="currentPage"
            layout="prev, pager, next"
            :page-size="perPage"
            :total="notifications.length"
        />

        <el-dialog
            class="new-voting-dialog"
            title="Уведомление"
            :visible.sync="dialogVisible"
            :class="{dialog_full_display: !$isMobile()}"
            :show-close="false"
            :width="dialogWidth"
        >

            <template v-slot:title>
                <el-container style="justify-content: space-between; align-content: center">
                    <div class="title">Уведомление</div>
                    <el-link type="primary" :underline="false" style="font-size: 18px" @click="onCancel">
                        <span v-if="$vssWidth > 600">Закрыть</span>
                        <i class="el-icon--right el-icon-error close-icon"></i>
                    </el-link>
                </el-container>
            </template>

            <div class="notification-modal">
                <div class="date">
                    <i class="fas fa-exclamation-circle"></i>
                    <span class="date-text">{{ dialogVisible.ppnDttm | formatDate }}</span>
                </div>

                <div class="text">
                    <vue-markdown :key="dialogVisible.id">{{ dialogVisible.msg }}</vue-markdown>
                </div>

                <div class="notification-modal-button" @click="goToVoting(dialogVisible)">Перейти в карточку голосования</div>
            </div>
        </el-dialog>

    </el-container>

</template>

<script>
import {mapActions} from "vuex";
import {mapFields} from "vuex-map-fields";
import moment from 'moment';
import VueMarkdown from 'vue-markdown'
import VueScreenSize from "vue-screen-size";

export default {
    mixins: [VueScreenSize.VueScreenSizeMixin],

    components: {
        VueMarkdown
    },

    data: () => ({
        dialogVisible: false,
        perPage: 5,
        currentPage: 1,
    }),

    created() {
        this.get_me();
        this.get_notifications();
    },

    filters: {
        formatDate(data) {
            return moment(data).format('DD.MM.YYYY в HH:mm')
        },
    },

    computed: {
        ...mapFields("user", ["me"]),
        ...mapFields("notifications", ["notifications"]),

        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },

        paginatedNotifications() {
            if (!this.notifications.length) return [];
            return this.notifications.slice((this.currentPage - 1) * this.perPage, ((this.currentPage - 1) * this.perPage) + this.perPage);
        },
    },

    methods: {
        ...mapActions("user", ["get_me"]),
        ...mapActions("notifications", ["get_notifications"]),

        openNotification(item) {
            this.dialogVisible = item;
        },

        onCancel() {
            this.dialogVisible = false;
        },

        goToVoting(item) {
            this.$router.push({name: "vote", params: {id: item.votingId}})
        },
    },

}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";
@import "~@fortawesome/fontawesome-free/css/all.css";

.notification {
    .notification-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;

            .date {
                display: flex;
                align-items: center;
                background: #f2f4f7;
                padding: 4px;
                border-radius: 15px;

                i {
                    font-size: 20px;
                    color: #05c78a;
                    margin-right: 15px;

                    &.dim {
                        color: #888888;
                    }
                }

                .date-text {
                    color: #909399;
                    font-weight: bold;
                    font-size: 15px;
                    padding-right: 5px;
                }
            }

            .text {
                color: #909399;
                font-weight: bold;
                font-size: 15px;
                margin-left: 30px;
                max-width: 80%;
            }
        }

        .eye {
            color: #1d9ef9;
            font-size: 20px;
            cursor: pointer;
        }
    }
}

.notifications-pagination {
    margin-top: 20px;
}

.notification-modal {
    .date {
        display: flex;
        align-items: center;
        background: #f2f4f7;
        padding: 4px;
        border-radius: 15px;
        width: 180px;

        i {
            font-size: 20px;
            color: #05c78a;
            margin-right: 15px;

            &.dim {
                color: #888888;
            }
        }

        .date-text {
            color: #909399;
            font-weight: bold;
            font-size: 15px;
            padding-right: 5px;
        }
    }

    .text {
        color: #111;
        font-weight: bold;
        font-size: 15px;
        margin-top: 20px;
    }

    .notification-modal-button {
        color: #1d9ef9;
        cursor: pointer;
        border: 2px solid #1d9ef9;
        text-align: center;
        width: 290px;
        height: 30px;
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
        border-radius: 15px;
        margin-top: 35px;
    }
}

.profile {
    display: flex;
    flex-wrap: wrap;
}

.box-card {
    width: 100%;
}

.title {
    font-size: 22px;
    font-weight: bold;
}

.close-icon {
    font-size: 24px;
}

.el-dialog__wrapper.warning-dialog {
    ::v-deep .el-dialog__header {
        background: $dom-light-gray;
        height: 34px;
    }

    ::v-deep .el-dialog__title {
        font-size: 26px;
    }
}

.dialog_full_display {
    max-height: 60vh;
    margin-top: 35px;
    width: 100%;
}

.new-voting-dialog {
    ::v-deep .el-dialog {
        height: 100vh !important;
        margin-top: 0 !important;
    }

    ::v-deep .el-dialog__header {
        position: relative;
        z-index: 10;
        box-shadow: $dom-shadow;
        padding: 20px 20px;
    }
}
</style>
